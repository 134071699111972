// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

/**
 * Code reference: 
 * author: maerics <https://stackoverflow.com/questions/6035071/intercept-paste-event-in-javascript>
 * */
 let defaultElementPast = false
 let inputCardOrTrackOnFocus = false;
 /**
  * Code reference: 
  * author: The_Butcher <https://stackoverflow.com/questions/5271129/do-an-alert-on-keyup-only-if-the-key-is-a-letter-or-number>
  * */
 function verifyKeyUpEventIsLetter(event){
   if (event.which <= 90 && event.which >= 48) return true;
   return false;
 }
 
 
 function get_clipboardData(){
   let pastedText = false;
 
   if (window.clipboardData && window.clipboardData.getData) { // IE
     pastedText = window.clipboardData.getData('Text');
   } else if (defaultElementPast.clipboardData && defaultElementPast.clipboardData.getData) {
     pastedText = defaultElementPast.clipboardData.getData('text/plain');
   }
 
   return pastedText;
 }
 
 function get_formToUseTrackingAndCards(elementItem){
   if ( elementItem ){
       let currentInput = elementItem.closest('form');
       return currentInput;
   } else {
     return false;
   }
 }
 
 function get_trackingInput(data){
   let input = document.querySelector('#tracking');
 
   if( input ){
     input.value = data;
     get_formToUseTrackingAndCards(input).submit();
   }
 
   return input;
 } 
 
 function get_cartaoInput(data){
   let input = document.querySelector('#code');
 
   if( input ){
     input.value = data;
     get_formToUseTrackingAndCards(input).submit();
   }
 
   return input;
 } 
 
 function submitClipboardDataInput(clipboardData){
   
   if( clipboardData ){
     //Tracking
     if ( clipboardData.length > 10 && clipboardData.length < 15  ) {
       get_trackingInput(clipboardData)
     } else if ( clipboardData.length >= 8  ) {
       get_cartaoInput(clipboardData);
     }
   }
 }
 
 window.addEventListener('paste', function(e){
  if( inputCardOrTrackOnFocus == false ){
    defaultElementPast = e;
    let clipboardData = get_clipboardData();
    submitClipboardDataInput(clipboardData);
  }
 });
 
 
 let textCodeReader = '';
 let timeoutRunTextCodeReader = false;
 
window.addEventListener('keyup', function(e){
  if( inputCardOrTrackOnFocus == false ){
    if( isFinite(e.key) || verifyKeyUpEventIsLetter(e)){
      textCodeReader = textCodeReader.replace('undefined', '') + '' + e.key;
      clearInterval(timeoutRunTextCodeReader);
      timeoutRunTextCodeReader = setTimeout(function(){
        clearInterval(timeoutRunTextCodeReader);
        submitClipboardDataInput(textCodeReader);
        textCodeReader = '';
      }, 400);
    }
  }
});

function onInputFocusOut(){
  inputCardOrTrackOnFocus = false;
}

function onInputFocusIn(){
  inputCardOrTrackOnFocus = true;
}


function inputsPreventPastAndLeitorFN(){
  let inputsPreventPastAndLeitor = document.querySelectorAll('#tracking, #code');
  
  if( inputsPreventPastAndLeitor ){
    inputsPreventPastAndLeitor.forEach(function(input){
      input.addEventListener('focus', onInputFocusIn);
      input.addEventListener('blur', onInputFocusOut);
    });
  }
}

/**
 * Based on https://developer.mozilla.org/pt-BR/docs/Web/API/MutationObserver
 */
// seleciona o nó alvo
var target = document.querySelector('html');

// cria uma nova instância de observador
var observer = new MutationObserver(function(mutations) {
  inputsPreventPastAndLeitorFN();
});

// configuração do observador:
var config = { subtree: true, attributes: true };

// passar o nó alvo, bem como as opções de observação
observer.observe(target, config);